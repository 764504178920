<template lang="pug">
    .main-wrapper.endpoints-salvar
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /
                            <router-link :to='{ name: "endpoints" }'> Serviço Externo</router-link> /
                            <b>{{  model.id ? 'Editar' : 'Adicionar' }}</b>
    
            form(@submit.prevent='handleSubmit()')
                ProgressBar(v-if='waitingForm' mode="indeterminate")
                div(v-else)
                    Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } serviço externo`")
                        .p-grid.p-fluid.p-align-end(style='margin: 0 auto;')
    
                            .p-col-4(:class="{ 'form-group--error': submitted && $v.model.nm_nome.$error }")
                                label.form-label Nome:
                                InputText(type='text' v-model='$v.model.nm_nome.$model')
                                .feedback--errors(v-if='submitted && $v.model.nm_nome.$error')
                                    .form-message--error(v-if="!$v.model.nm_nome.required") <b>Nome</b> é obrigatório.
    
                            .p-col-4(:class="{ 'form-group--error': submitted && $v.model.ds_endpoint.$error }")
                                label.form-label Url do Endpoint:
                                InputText(type='text' v-model='$v.model.ds_endpoint.$model')
                                .feedback--errors(v-if='submitted && $v.model.ds_endpoint.$error')
                                    .form-message--error(v-if="!$v.model.ds_endpoint.required") <b>URL</b> é obrigatório.

                            .p-col-4(:class="{ 'form-group--error': submitted && $v.model.ds_token.$error }")
                                label.form-label Token:
                                InputText(type='text' v-model='$v.model.ds_token.$model')
    
                            .p-col-6(:class="{ 'form-group--error': submitted && $v.model.ds_usuario.$error }")
                                label.form-label Usuário:
                                InputText(type='text' v-model='$v.model.ds_usuario.$model')
    
                            .p-col-6(:class="{ 'form-group--error': submitted && $v.model.ds_senha.$error }")
                                label.form-label Senha:
                                InputText(type='text' v-model='$v.model.ds_senha.$model')
    
                            .p-col-3(:class="{ 'form-group--error': submitted && $v.model.ie_status.$error }")
                                label.form-label Status:
                                Checkbox(v-model='$v.model.ie_status.$model' :binary="true")
    
                            .p-col-3(:class="{ 'form-group--error': submitted && $v.model.ds_identificador.$error }")
                                label.form-label Identificador:
                                InputText(type='text' :readonly="podeEditar()" v-model='$v.model.ds_identificador.$model')
    
                            .p-col-3(:class="{ 'form-group--error': submitted && $v.model.ds_client_id.$error }")
                                label.form-label Client ID:
                                InputText(type='text' v-model='$v.model.ds_client_id.$model')

                            .p-col-3(:class="{ 'form-group--error': submitted && $v.model.ds_client_secret.$error }")
                                label.form-label Client Secret:
                                InputText(type='text' v-model='$v.model.ds_client_secret.$model')
    
                            .p-col-12.mt-2
                                Button(label='Salvar' type="submit")
    </template>
    
    <style lang="scss">
        .endpoints-salvar {
            .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
            .p-inputtext[disabled=disabled] {
                color: #000;
                font-weight: 600;
                background-color: #ddd;
            }
            .p-picklist-source-controls,
            .p-picklist-target-controls {
                display: none;
            }
            .p-picklist-source-wrapper,
            .p-picklist-target-wrapper {
                width: 45%;
                @media all and (max-width: 576px) {
                    width: 100%;
                }
            }
            .p-picklist-buttons-cell {
                text-align: center;
                .p-button {
                    display: none !important;
                    &:first-of-type, &:nth-of-type(3) {
                        display: inline-block !important;
                    }
                }
            }
            .text-box {
                margin: 0;
                font-size: 16px;
            }
            .input-sexo .p-button {
                width: 50% !important;
            }
        }
    </style>
    
    <script>
        import ProgressBar from 'primevue/progressbar'
        import Panel from 'primevue/panel'
        import InputText from 'primevue/inputtext'
        import InputMask from 'primevue/inputmask'
        import Password from 'primevue/password'
        import Button from 'primevue/button'
        import Dropdown from 'primevue/dropdown'
        import SelectButton from 'primevue/selectbutton'
        import Tooltip from 'primevue/tooltip'
        import Checkbox from 'primevue/checkbox'
    
        import { ServicosExternos } from './../../middleware'
        import { required, minLength } from 'vuelidate/lib/validators'
    
        export default {
            components: {
                ProgressBar, Panel, InputText, Button, Password,
                    InputMask, SelectButton, Dropdown, Tooltip, Checkbox
            },
            directives: { tooltip: Tooltip },
            created () {
                this.waiting = true
    
                let id = 0
                if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id)
                if (id != 0) {
                    ServicosExternos.find(id).then(response => {
                        if (response.status == 200) {
                            let keys = Object.keys(this.model)
                            
                            keys.forEach(key => this.model[key] = response.data[key])
                            
                            this.model.id = id;
                        }
                        this.waiting = false
                    })
                } else this.waiting = false  
    
            },
            data () {
                return {
                    model: {
                        nm_nome: '',
                        ds_endpoint: null,
                        ds_token: null,
                        ds_usuario: '',
                        ds_senha: '',
                        ds_identificador: '',
                        ds_client_id: '',
                        ds_client_secret: '',
                        ie_status: true
                    },
                    options: {
                    },
                    waiting: true,
                    waitingForm: false,
                    submitted: false
                }
            },
            validations () {
                let v = {
                    model: {
                        nm_nome: { required, minLength: minLength(2) },
                        ds_endpoint: { required },
                        ds_token: {},
                        ds_usuario: {},
                        ds_senha: {},
                        ds_identificador: { required },
                        ds_client_id: {},
                        ds_client_secret: {},
                        ie_status: {}
                    }
                }
                
                return v
            },
            methods: {
                podeEditar(){
                    if (this.model.id == 0 || !this.model.id){
                        return false
                    }
                    return true
                },
                handleSubmit () {
                    this.submitted = true
                    
                    this.$v.$touch()
                    if (this.$v.$invalid) return 0

                    let ref = this.model.ds_identificador
                    if (this.model.id){
                        delete this.model.ds_identificador
                    }
                    let dataSend = Object.assign({}, this.model)
                    
                    this.waitingForm = true
                    
                    ServicosExternos.save(dataSend).then(response => {
                        if (([200, 201]).includes(response.status)) {
                            if (! dataSend.id) this.$router.push({ name: 'servicos-externos' })
                            this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                        } else {
                            this.$toast.success('Erro ao salvar os dados do formulário.', { duration: 3000 })
                            if (response.data.message){
                                for (let i = 0; i < response.data.message.length; i++){
                                    this.$toast.error(response.data.message[i])
                                }
                                Object.keys(response.data.message).forEach((key) => {
                                    this.$toast.error(`${key} - ${response.data.message[key]}`)
                                })
                            }else{
                                this.$toast.error(response.data.detail)
                            }
                        }
                        this.waitingForm = false
                        this.model.ds_identificador = ref
                    })
                }
             }
          }
    </script>